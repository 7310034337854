.nav {
    @include regular-font;
    font-size: 14px;
    text-align: right;
    text-transform: uppercase;
    display: flex;
    justify-content: space-between;
    color: white;
    height: 15dvh;
    background-color: black;
    width: 80%;
    margin: 0 auto;

    img {
        width: auto;
        height: 90%;
    }
    ul {
        float: right;
        white-space: nowrap;
        li {
            padding: 20px;
            display: inline-block;
            a {
                text-decoration: none;
                color: white;
            }
            .current-page {
                color: $primary;
            }
            a:hover {
                color: $primary;
                cursor: pointer;
                text-decoration: underline;
                transition: 0.5s;
            }
        }
    }
    .logo {
        display: flex;
        align-items: center;
        width: 20%;
    }
    .pages {
        margin: auto 20px;
        text-align: center;
        align-items: center;
        width: 60%;
    }
}
.mobile-nav {
    width: 100% !important;
    .pages {
        text-align: end !important;
        button {
            border: none;
            background-color: black;
            color: white;
            font-size: 40px;
            padding: 7px;
        }
        button:hover {
            transition: 0.5s;
            color: $primary;
            cursor: pointer;
        }
    }
    ul {
        position: absolute;
        right: 0;
        top: 15dvh;
        background-color: black;
        width: 0px;
        height: calc(100dvh - 60px);
        transition: all 0.3s ease-in;
        overflow: hidden;
        z-index: 1;
        li {
            border-bottom: 1px solid $primary;
            display: list-item;
            padding: 20px;
            a {
                text-decoration: none;
                color: white;
            }
            a:hover {
                color: $primary;
                cursor: pointer;
                text-decoration: underline;
                transition: 0.5s;
            }
        }
    }
    .pages.active {
        ul {
            width: 270px;
        }
    }
}
@media screen and (max-width: 800px) {
    .nav{
        height: 12dvh;
    }
    .mobile-nav {
        ul {
            top: 12dvh;
        }
    }
}
