.footer {
    //position: relative;
    color: white;
    width: 100%;
    background-color: black;
    min-height: 350px;

    @include regular-font;

    .higher-footer {
        display: flex;
        justify-content: space-between;
        width: 85%;
        margin: 0 auto;
        padding: 45px 0;
        .contact-footer {
            display: flex;
            justify-content: center;
            margin: auto 20px;
            text-align: center;
            align-items: center;
            width: 20%;
        }
        h1 {
            font-family: "Casta";
            font-style: normal;
            font-weight: 600;
            font-size: 60px;
        }

        border-bottom: 1px solid;
    }
    .lower-footer {
        width: 85%;
        text-transform: uppercase;
        padding: 30px;
        display: flex;
        justify-content: space-between;
        font-size: 12px;
        margin: 0 auto;
        ul {
            li {
                padding: 20px;
                display: inline-block;
                a {
                    text-decoration: none;
                    color: white;
                }
                a:hover {
                    color: $primary;
                    cursor: pointer;
                    text-decoration: underline;
                    transition: 0.5s;
                }
            }
        }

        .logo {
            display: flex;
            align-items: center;
            width: 20%;
            img {
                height: 135px;
                width: 135px;
            }
        }
    }
}
.outer-footer {
    padding-bottom: 1rem;
    text-align: center;
    width: 100%;
    background-color: black;
    color: white;
    span {
        @include regular-font;
    }
    a {
        text-decoration: none;
        color: $primary-opac;
    }
    a:hover {
        text-decoration: underline;
    }
}
.mobile-outer-footer {
    font-size: 0.8em;
    padding-top: 2rem;
    background-color: $secondary !important;
    color: white !important;
    .copyright {
        padding: 0 1rem;
    }
}
@media screen and (max-width: 900px) {
    .footer {
        h1 {
            padding-bottom: 15px;
        }
        .higher-footer {
            flex-direction: column;
            align-items: center;
        }
        .logo {
            display: none !important;
        }
        .lower-footer {
            justify-content: center;
            align-items: center;
        }
    }
}
@media screen and (max-width: 700px) {
    .footer {
        h1 {
            font-size: 45px !important;
        }
        .lower-footer {
            font-size: 14px;
            ul {
                li {
                    padding: 15px;
                    text-align: center;
                    display: block;
                }
            }
        }
    }
}
@media screen and (max-width: 500px) {
    .footer {
        h1 {
            font-size: 30px !important;
        }
    }
}
