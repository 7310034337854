.accueil {
    .postit {
        //display: none;
        position: absolute;
        right: 8%;
        top: 200px;

        .left {
            //hexagone
            float: left;
            border-right: 45px solid $primary_opac;
            border-top: 78px solid transparent;
            border-bottom: 76px solid transparent;
        }
        .middle {
            //hexagone
            float: left;
            width: 90px;
            height: 153px;
            background-color: $primary_opac;
            //style
            display: flex;
            align-items: center;
            justify-content: center;

            span {
                @include bold-font;
                font-size: 1.3rem;
                text-align: center;
                color: black;
            }
        }
        .right {
            //hexagone
            float: left;
            border-left: 45px solid $primary_opac;
            border-top: 78px solid transparent;
            border-bottom: 76px solid transparent;
        }
    }
    .postit:hover {
        transition: 0.5s;
        transform: rotate(360deg);
        span {
            color: white;
        }
    }
    color: white;
    .section1 {
        width: 80%;
        margin: 0 auto;
        height: 82dvh;
        @include section-margin;

        .border-left {
            height: 160px;
            margin: 50px 0;
            border-left: 1px solid white;
        }
        .welcome-title {
            height: auto;
            h1 {
                font-family: "Casta";
                font-style: normal;
                font-weight: 600;
                font-size: 95px;
            }

            h3 {
                white-space: nowrap;
                width: 368px;
                height: 30px;
                font-style: normal;
                font-weight: 275;
                font-size: 35px;
                line-height: 100%;
            }
        }
        .background {
            position: absolute;
            z-index: -2;
            width: 60%;
            height: 60%;
            right: 13%;
            top: 225px;
            border-radius: 3px;

            img {
                width: 100%;
                height: 100%;
                opacity: 0.65;
                object-fit: cover;
            }
        }
    }

    .section2 {
        width: 100%;
        height: 100dvh;
        @include section-margin;
        

        .container {
            display: flex;
            flex-direction: row;
            width: 80%;
            margin: 0 auto;
            height: 100%;
            .img-grid {
                display: flex;
                align-items: center;
                width: 50%;
                height: 100%;
                .img-surounding {
                    border: 2px solid $primary;
                    z-index: -1;
                    width: 90%;
                    height: 90%;

                    img {
                        width: 100%;
                        height: 100%;
                        margin: 5% -5%;
                    }
                }
            }
            .text-grid {
                margin: 0 auto;
                flex-direction: column;
                width: 50%;
                h4 {
                    padding: 20px 0;
                }
                h1 {
                    padding: 1rem 0;
                    font-family: "Casta";
                    font-style: normal;
                    font-weight: 600;
                    font-size: 50px;
                }
                p {
                    padding-bottom: 0.5rem;
                    font-style: normal;
                    font-weight: 400;
                    font-size: 18px;
                }
                .wrapper {
                    display: grid;
                    grid-template-columns: 50% 50%;
                    padding-top: 1rem;
                    .speciality {
                        display: flex;
                        height: 25dvh;
                        padding: 1rem 1rem 1rem 0;
                        align-items: center;
                        img {
                            height: auto;
                            width: 30%;
                        }
                        .spec-text {
                            width: 100%;
                            padding-left: 1.5rem;
                            h5 {
                                @include regular-font;
                                color: $primary;
                                font-size: 1em;
                            }
                            span {
                                display: block;
                                width: 100%;
                                color: white;
                                font-size: 1em;
                            }
                        }
                    }
                }
            }
        }
    }

    .section3 {
        width: 100%;
        /*background:
            linear-gradient(0deg, rgba(255, 255, 255, 0.7), rgba(255, 255, 255, 0.7)),
            url(../images/fond-15.png) no-repeat;*/
        background-color: white;
        padding-bottom: 100px;
        height: 100dvh;
        @include section-margin;
        .container {
            width: 80%;
            height: 100%;
            margin: 0 auto;
            h4,
            span {
                color: black;
            }
            h4 {
                padding: 20px 0;
                padding-bottom: 20px;
            }
            h4:after {
                border-top: 2px solid black;
            }
        }
        .partners {
            display: flex;
            justify-content: center;
            align-items: center;
            flex-wrap: wrap;
            height: 90dvh;
            img {
                padding: 5px;
                width: 10%;
                height: auto;
                object-fit: contain;
            }
        }
        .swiper-wrapper {
            margin-bottom: 2rem;
        }
    }
}
@media screen and (max-width: 1250px) {
    .accueil {
        .section2 {
            .container {
                width: 90%;
                .text-grid {
                    .wrapper {
                        .speciality {
                            img {
                                height: auto;
                                width: 25%;
                            }
                            .spec-text {
                                padding-left: 0.8rem;
                                h5 {
                                    font-size: 0.9em;
                                }
                                span {
                                    font-size: 0.9em;
                                }
                            }
                        }
                    }
                }
            }
        }
    }
}

@media screen and (max-width: 1000px) {
    .accueil {
        .section1 {
            .welcome-title {
                h1 {
                    font-size: 80px !important;
                }
            }
        }
        .postit {
            .left {
                //hexagone
                border-right: 30px solid $primary_opac;
                border-top: 52px solid transparent;
                border-bottom: 52px solid transparent;
            }
            .middle {
                width: 60px;
                height: 104px;
                span {
                    font-size: 1rem;
                }
            }
            .right {
                border-left: 30px solid $primary_opac;
                border-top: 52px solid transparent;
                border-bottom: 52px solid transparent;
            }
        }
        .section2 {
            .container {
                .text-grid {
                    .wrapper {
                        .speciality {
                            img {
                                height: auto;
                                width: 25%;
                            }
                            .spec-text {
                                padding-left: 0.8rem;
                                h5 {
                                    font-size: 0.8em;
                                }
                                span {
                                    font-size: 0.8em;
                                }
                            }
                        }
                    }
                }
            }
        }
        .section3 {
            .partners {
                img {
                    width: 11%;
                }
            }
        }
    }
}
@media screen and (max-width: 830px) {
    .accueil {
        .section2 {
            height: fit-content;
            flex-direction: column;
            align-items: center;
            width: 100%;
            .container {
                flex-direction: column;
                width: 90%;
                .img-grid {
                    height: 70dvh;
                    margin-bottom: 5rem;
                    justify-content: center;
                    width: 100%;
                    img {
                        height: 100%;
                        width: 100%;
                    }
                }
                .text-grid {
                    width: 100%;
                    .wrapper {
                        .speciality {
                            img {
                                height: auto;
                                width: 30%;
                            }
                            .spec-text {
                                padding-left: 1rem;
                                h5 {
                                    font-size: 1em;
                                }
                                span {
                                    font-size: 1em;
                                }
                            }
                        }
                    }
                }
            }
        }
        .section3 {
            .partners {
                img {
                    width: 12%;
                }
            }
        }
    }
}

@media screen and (max-width: 700px) {
    .accueil {
        .section1 {
            .welcome-title {
                h1 {
                    font-size: 60px !important;
                }
                h3 {
                    font-size: 25px;
                }
            }
            .background {
                right: 0;
                width: 75%;
                height: 60%;
            }
        }
        .section3 {
            .partners {
                img {
                    width: 13%;
                }
            }
        }
    }
}
@media screen and (max-width: 500px) {
    .accueil {
        .postit {
            top:16dvh;
            right: 1.5%;
            .left {
                //hexagone
                border-right: 24px solid $primary_opac;
                border-top: 41.6px solid transparent;
                border-bottom: 41.6px solid transparent;
            }
            .middle {
                width: 48px;
                height: 83.2px;
                span {
                    font-size: 0.8rem;
                }
            }
            .right {
                border-left: 24px solid $primary_opac;
                border-top: 41.6px solid transparent;
                border-bottom: 41.6px solid transparent;
            }
        }
        .section1 {
            width: 90%;
            .welcome-title {
                h1 {
                    font-size: 50px !important;
                }
                h3 {
                    font-size: 20px;
                }
            }
            .background {
                height: 45%;
            }
        }
        .section2 {
            .container {
                .text-grid {
                    .wrapper {
                        display: flex !important;
                        flex-direction: column !important;
                    }
                }
            }
        }

        .section3 {
            .container {
                width: 90%;
            }

            .partners {
                img {
                    width: 17%;
                }
            }
        }
    }
}

//X-large screens
@media screen and (min-height: 1000px) {
    .accueil {
        .welcome-title {
            height: auto;
            h1 {
                font-size: 110px !important;
            }

            h3 {
                font-size: 50px !important;
            }
        }
    }
}
