.apropos {
    background: url(../images/fond-34.png) center;
    -webkit-background-size: contain;
    -moz-background-size: contain;
    -o-background-size: contain;
    background-size: contain;
    .section1 {
        width: 80%;
        @include section-margin;
        height: 60dvh;
        display: flex;
        justify-content: center;
        align-items: center;
        
    

        .container {
            text-align: center;
            margin: 0 auto;
            font-family: "Casta";
            color: rgb(231, 231, 231);
            border: 3px solid rgb(231, 231, 231);
            width: 45%;
            height: fit-content;
            padding: 5rem;
            h1,
            p {
                text-align: center;
            }
            h1 {
                font-size: 2.5em;
            }
            p {
                font-size: 1.5em;
            }
        }
    }
    .section2 {
        z-index: -1;
        height: 100dvh;
        @include section-margin;
        padding-bottom: 120px;
        .container {
            display: flex;
            width: 80%;
            margin: 0 auto;
            justify-content: space-between;

            h4 {
                padding: 50px 20px 10px 20px;
            }
            .grid1,
            .grid2,
            .grid3 {
                @include regular-font;
                margin: 0 2rem;
                background-color: #292929c1;
                display: flex;
                flex-direction: column;
                width: 30%;
                //box-shadow: 0px 5px 15px #ffb74bbe;
                border: 1.5px solid rgba(255, 255, 255, 0.537);
                min-height: 500px;
            }
            .grid2 {
                margin: -2rem 0 2rem 0;
            }
            .text-grid {
                height: fit-content;
                ul {
                    padding: 20px;
                    list-style: none;
                    color: whitesmoke;
                    @include regular-font;
                    border-radius: 20px;
                }
                ul li {
                    padding: 0.7rem 0;
                    //text-align: center;
                    color: white;
                    font-size: 1em;
                }
                ul li:hover {
                    background-color: #ffb74b2f;
                    border-bottom: 1px $primary solid;
                    cursor: pointer;
                    transition: all 0.5s ease;
                }
                .custom-list {
                    color: $primary;
                }
            }
            .img-grid {
                display: flex;
                justify-content: center;
                align-items: center;
                width: 100%;
                height: 180px;
                .background {
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    margin: 0 auto;
                    z-index: 0;
                    width: 80%;
                    height: 80%;
                    opacity: 0.8;
                    background: url(../images/element-6.png) no-repeat center;
                    -webkit-background-size: contain;
                    -moz-background-size: contain;
                    -o-background-size: contain;
                    background-size: contain;
                    img {
                        width: 25%;
                        height: auto;
                    }
                }
            }
        }
    }
}
@media screen and (max-width: 800px) {
    .apropos {
        .section1 {
          
            margin-top: 4rem;
            .container {
                width: 75%;
                padding: 2rem;
                h1 {
                    padding-bottom: 1rem;
                    font-size: 2.2em;
                }
                p {
                    font-size: 1.4em;
                }
            }
        }
        .section2 {
            height: auto;
            .container {
                justify-content: center;
                align-items: center;
                flex-direction: column;
                width: 90%;
                .grid1,
                .grid2,
                .grid3 {
                    margin: 2rem !important;
                    min-height: auto;
                    height: fit-content;
                    width: 90%;
                }
            }
        }
    }
}
