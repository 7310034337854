@font-face {
    font-family: "Casta";
    src:
        local("Casta-ExtraLight"),
        url("../fonts/Casta/Casta-ExtraLight.otf") format("opentype");
}

//colors
$primary: #deaf39;
$primary_opac: #deaf39b6;
$secondary: #464646;
$gold-bee: #c49f79;
$pink-bee: #ff7471;

//fonts
@mixin regular-font {
    font-family: "aktiv-grotesk-extended", sans-serif;
    font-weight: 400;
    font-style: normal;
}

@mixin italic-font {
    font-family: "aktiv-grotesk-extended", sans-serif;
    font-weight: 400;
    font-style: italic;
}

@mixin bold-font {
    font-family: "aktiv-grotesk-extended", sans-serif;
    font-weight: 700;
    font-style: normal;
}
h4 {
    position: relative;
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    text-transform: uppercase;
    color: $primary;
    overflow: hidden;
}
//margin
@mixin section-margin {
    margin: 120px auto;
}

h4:after {
    content: "";
    display: inline-block;
    height: 0.5em;
    vertical-align: bottom;
    width: 150px;
    margin-right: -100%;
    margin-left: 10px;
    border-top: 2px solid white;
}

.contact-btn {
    display: flex;
    align-items: center;
    justify-content: center;
    white-space: nowrap;
    background: $primary;
    width: 158px;
    height: 14px;
    border-radius: 80px;
    opacity: 0.8;
    padding: 24px;
    font-weight: 700;
    font-size: 14px;
    text-transform: uppercase;
    letter-spacing: 0.02em;
    a {
        text-decoration: none;
        color: black;
    }
}
.contact-btn:hover {
    background: #ffffff;
    cursor: pointer;
    transition: 0.7ms;
    outline: 4px solid $primary;
    a {
        font-weight: 800;
        color: $primary;
    }
}
body {
    background-color: black;
}
.title-with-background {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    height: 60dvh;
    background:
        linear-gradient(0deg, rgba(0, 0, 0, 0.041), rgba(0, 0, 0, 0.501)),
        url(../images/ai-generated/crowd2.png) no-repeat center;

    //background: linear-gradient(335deg, rgba(255,140,107,1) 0%, rgba(255,228,168,1) 100%);
    //background-attachment: fixed;
    animation: breath 8s linear forwards;
    -webkit-background-size: cover;
    -moz-background-size: cover;
    -o-background-size: cover;
    background-size: cover;
    width: 100%;
    h1 {
        font-family: "Casta";
        font-style: normal;
        font-weight: 600;
        font-size: 6.5em;
        color: white;
        text-align: center;
    }
}
.error {
    @include regular-font;
    color: red;
}
.success {
    @include regular-font;
    color: rgb(1, 165, 1);
}
.loading {
    @include regular-font;
    color: rgb(0, 0, 0);
}
@media screen and (max-width: 900px) {
    .title-with-background {
        h1 {
            font-size: 3em;
        }
    }
}
//home background
@keyframes breath {
    0% {
        background-size: 140% auto;
    }
    //50% { background-size: 100% auto; }
    100% {
        background-size: 120% auto;
    }
}
