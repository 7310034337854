.social {
    @include regular-font;
    font-size: 1em;
    background-color: $secondary;
    display: flex;
    justify-content: center;
    align-items: center;
    color: white;
    width: 100%;
    margin: 0 auto;
    padding: 8px 0px;
    gap: 10px;
    height: 3dvh;
    
    .icon {
        color: $primary;
        font-size: 1.3em;
    }

    .social-details {
        span,
        a {
            padding: 0 0.4rem;
        }
    }

    .social-media {
        li {
            display: inline-block;
            padding: 0 0.6rem;
            font-size: 1.4em;
        }
    }
    a {
        text-decoration: none;
        color: white;
    }
    a:hover {
        color: $primary;
        cursor: pointer;
        transition: 0.5s;
    }
}
@media screen and (max-width: 550px) {
    .social { 
        flex-direction: column;
        padding: 10px 0;
        height: fit-content;
        .col {
            margin: 7px 0;
        }
        .social-media {

            li {
                padding: 0 10px;
            }
        }
    }
}
