.formulaire {
    .section1 {
        display: flex;
        flex-direction: column;
        min-height: 90dvh;
        background-color: white;

        h4 {
            padding: 1rem 2rem;
            color: black;
        }

        h4:after {
            border-top: 2px solid black;
        }

        .container {
            text-align: center;
            margin: 0 auto;
            min-height: 65dvh;
            width: 80%;
            display: flex;
            flex-direction: column;
            align-items: center;
            margin: 1rem auto;

            .tab-section {
                width: 100%;
                align-items: center;
                margin-bottom: 1rem;
                ul {
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    width: 100%;
                    margin: auto 0;
                    li {
                        @include regular-font;
                        display: inline-block;
                        list-style: none;
                        height: 45px;
                        border-bottom: 4px solid $primary;
                        width: 15%;
                        margin: 1rem 0.1rem;
                        padding: 0.5rem;
                        font-size: 0.9em;
                        text-align: center;
                    }
                    .current-slide {
                        background: $primary-opac;
                    }
                    .completed:hover {
                        cursor: pointer;
                    }
                }
            }

            .form-container {
                form {
                    width: 100%;
                }
                display: flex;
                flex-direction: column;
                align-items: center;
                justify-content: center;
                width: 70%;
                height: 100%;
                @include regular-font;
                .input-section {
                    display: flex;
                    justify-content: center;
                    flex-direction: column;
                    padding: 0.8rem 0;
                    width: 80%;
                    input[type="text"],
                    input[type="date"],
                    input[type="email"],
                    textarea {
                        align-self: center;
                        width: 100%;
                        height: 30px;
                        border: 1.5px solid rgba(87, 87, 87, 0.516);
                        outline: 2.5px solid $primary_opac;
                        border-radius: 2px;
                        @include regular-font;
                        transition: all 0.5s;
                    }
                    textarea {
                        height: 150px;
                    }
                    input[type="text"]:hover,
                    input[type="date"]:hover,
                    input[type="email"]:hover input[type="text"]:focus,
                    input[type="date"]:focus,
                    input[type="email"]:focus {
                        background-color: $primary_opac;
                        outline: 2.5px solid rgba(87, 87, 87, 0.516);
                    }
                    label {
                        text-align: start;
                        align-self: flex-start;
                        padding: 0.3rem 0;
                    }
                    .checkboxes-title {
                        padding-bottom: 2rem;
                        font-weight: bold;
                    }
                }
                .submit-form {
                    width: 100%;
                    ul {
                        //border: 2px solid $primary;
                        width: 100%;
                        height: fit-content;
                        list-style: none;
                        li {
                            display: flex;

                            min-height: 35px;
                            h5,
                            span {
                                min-height: 35px;
                                align-items: center;
                                border: 2px solid $primary;
                                width: 50%;
                            }
                            h5 {
                                background-color: $primary-opac;
                                font-size: 1em;
                            }
                        }
                    }
                }
                .status-container {
                    display: flex;
                    flex-direction: column;
                    justify-content: center;
                    align-items: center;
                    .success-container,
                    .loading-container,
                    .error-container {
                        display: flex;
                        flex-direction: column;
                        justify-content: center;
                        align-items: center;
                        p {
                            text-align: center;
                        }

                        img {
                            padding: 30px;
                            width: 60px;
                            height: 60px;
                        }
                    }
                }
            }
            .checkbox-section {
                display: grid;
                grid-template-columns: 45% 45%;
                justify-content: space-between;
                align-items: center;
                .checkbox-wrapper {
                    display: flex;
                    flex-direction: row;
                    align-items: center;
                    justify-content: space-between;
                    height: 40px;
                    width: 100%;
                    label {
                        font-size: 0.9em;
                        padding: 0.3rem 0;
                    }
                    input[type="checkbox"] {
                        appearance: none;
                        -webkit-appearance: none;
                        display: flex;
                        align-items: center;
                        justify-content: center;
                        align-items: center;
                        font: inherit;
                        accent-color: $primary;
                        border: 0.1em solid $primary;
                        border-radius: 0.1em;
                        font-size: 1em;
                        height: 1.3rem;
                        width: 1.3rem;
                        transform: scale(1);
                        background-color: #fff;
                    }
                    input[type="checkbox"]:checked:after {
                        display: flex;
                        align-items: center;
                        justify-content: center;
                        align-items: center;
                        content: "✔";
                        background-color: $primary;
                        color: white;
                        padding: 0%;
                        height: 1.3rem;
                        width: 1.3rem;
                    }
                    input[type="checkbox"]:hover {
                        cursor: pointer;
                    }
                }
            }
            .btn-section {
                display: flex;
                justify-content: center;
                align-items: center;
                text-align: center;
                min-width: 50%;
                margin: 0 auto;
                margin-top: 1.5rem;

                button,
                input {
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    color: white;
                    background-color: $primary;
                    text-align: end;
                    float: right;
                    height: 30px;
                    border: none;
                    width: 100%;
                    text-align: center;
                    @include regular-font;
                    font-size: 1.2em;
                    margin: 0 0 3.5rem 0;
                    border-radius: 2px;
                    padding: 1.3rem;
                    transition: all 0.2s;
                    cursor: pointer;
                }
                input {
                    margin: auto 0;
                    padding: 0.5rem;
                    height: 40px;
                }
                .next:hover {
                    background-color: white;
                    outline: 1.5px solid $primary;
                    color: $primary;
                }
            }
            .back {
                width: 100%;
                justify-content: left;
                margin-top: 0.3rem;

                button {
                    margin: 0 0 1.2rem 0;
                    width: 40%;
                    background: none;
                    color: $primary;
                    font-size: 1em;
                }
                button:hover {
                    text-decoration: underline;
                }
            }
            .error-message {
                @include regular-font;
                color: rgb(200, 0, 0);
                text-align: center;
                background:rgba(255, 0, 0, 0.14);
            }
        }
    }
}
@media screen and (max-width: 830px) {
    .formulaire {
        h4 {
            padding: 0.4rem !important;
            font-size: 0.8em !important;
            font-weight: 500;
            span {
                display: block;
                white-space: normal;
            }
        }
        .section1 {
            height: fit-content;
            @include section-margin;
            .container {
                height: fit-content;
                width: 100% !important;
                margin: 0 auto;
                .tab-section {
                    ul {
                        li {
                            width: 25%;
                            font-size: 0.8em;
                        }
                    }
                }

                #btn-next {
                    margin: 0 0 2.5rem 0 !important;
                }
            }
        }
        .input-section {
            justify-content: center;
            align-items: center;
        }
        .form-container {
            width: 100% !important;
            .submit-form {
                width: 100%;
                ul {
                    li {
                        align-items: center;
                        justify-content: center;
                        flex-direction: column;
                        h5,
                        span {
                            width: 90% !important;
                        }
                    }
                }
            }
        }
        .checkbox-section {
            display: flex !important;
            flex-direction: column !important;
            align-items: center;
            justify-content: center;
            width: 300px;
            label {
                width: 90%;
            }
        }
        .error-message{
            padding: 1.5rem;
        }
    }
}
