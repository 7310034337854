.placeholder {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    width: 100%;
    height: 90dvh;
    color: white;

    h1 {
        font-size: 4rem;
        font-family: "Casta";
          font-weight: 600;
    }
    p,
    span {
        @include italic-font;
        font-size: 1.5rem;
    }
    span {
        color: $primary;
    }

    img {
        width: auto;
        height: 400px;
        margin-bottom: 1.5rem;
    }
}
@media screen and (max-width: 600px) {
    .placeholder {
        height: 100dvh;
        h1 {
            white-space: nowrap;
            font-size: 1.8rem;
        }
        p,
        span {
            font-size: 1rem;
        }

        img {
            width: auto;
            height: 300px;
        }
    }
}
