//Global Settings
* {
    padding: 0;
    margin: 0;
    text-align: left;
}
html {
    scroll-behavior: smooth;
}

@import "components";
@import "footer";
@import "navbar";
@import "social";
@import "accueil";
@import "placeHolder";
@import "apropos";
@import "services";
@import "news";
@import "events";
@import "formulaire";
@import "eventDetails";
@import "factscta";

