.fact-section {
    height: 40dvh;
    background: url(../images/ai-generated/crowd2.png) no-repeat center;
    //background: linear-gradient(335deg, rgba(255,140,107,1) 0%, rgba(255,228,168,1) 100%);
    background-attachment: fixed;
    -webkit-background-size: cover;
    -moz-background-size: cover;
    -o-background-size: cover;
    background-size: cover;
    width: 100%;
    .container {
        width: 80%;
        height: 100%;
        margin: 0 auto;
        display: flex;
        justify-content: space-around;
        align-items: center;
        .fact {
            font-family: "Casta";
            font-style: normal;
            font-weight: 600;
            font-size: 1.5em;
            height: 50%;
            width: 15%;
            border: solid white 2px;
            border-radius: 2px;
            color: white;
            display: flex;
            align-items: center;
            justify-content: center;
            flex-direction: column;
            padding: 1.5rem;

            .fact-number {
                font-size: 2.7em;
                color: $primary;
                .plussign {
                    font-size: 1em;
                    color: white;
                    padding-left: 0.5rem;
                }
                .num {
                }
            }
            h1 {
                text-align: center;
            }
        }
    }
}
@media screen and (max-width: 1200px) {
    .fact-section {
        .container {
            .fact {
                width: 20%;
            }
            .fact-number {
                font-size: 2em;
            }
            h1 {
                font-size: 1em;
            }
        }
    }
}

@media screen and (max-width: 830px) {
    .fact-section {
        height: fit-content;
        .container {
            width: 100%;
            flex-direction: column;
            .fact {
                width: 45%;
                height: 15dvh;
                margin: 1rem 0;
            }
        }
    }
}
